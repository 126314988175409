import React from "react"
import styled from "styled-components"

const CompWrap = ({ children }) => {
  return <Wrap>{children}</Wrap>
}

export default CompWrap

const Wrap = styled.div`
  width: calc(100% - 1rem);
  max-width: 1000px;
  margin: 0 auto;
`
